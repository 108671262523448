.termsofUse {
  font-family: "IBM Plex Sans";
  padding-bottom: 80px;
  a {
    text-decoration: none;
    padding-left: 5px;
  }
  p {
    font-size: 18px;
    color: #162D43;
    text-align: justify;
  }
  .headings {
    letter-spacing: 0px;
    color: #162d43;
    opacity: 1;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 600;
  }
  .content {
    text-align: left;
    letter-spacing: 0px;
    color: #162d43;
    opacity: 1;
    text-align: justify;
  }
  .ReportSubText {
    padding-left: 10px;
  }
  .subContent_main {
    margin-top: 20px;
    .subContent {
      padding-bottom: 5px;
      display: flex;
      align-items: baseline;
      text-align: justify;
      span {
        margin-left: 10px;
        font-size: 18px;
        color: #162D43;
      }
    }
  }
  .content_refund {
  }
  .btn_wrapper {
    margin-top: 41px;
    margin-bottom: 41px;
    display: flex;
    .btn_1 {
      margin-right: 10px;
      background-color: #ebf1f8;
      border-color: #ebf1f8 !important;
      color: #7b8a98;
      border-radius: 5px;
      opacity: 1;
      font-size: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-family: "IBM Plex Sans";
    }
    .active_btn1 {
      background-color: #1485d8;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
}
@media screen and (max-width:767px) {
  .termsofUse {
    padding-bottom: 30px;
    .btn_wrapper {
      margin: 25px 0;
      .btn_1 {
        font-size: 13px;
      }
    }
    .headings {
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
    }
    .subContent_main {
      .subContent {
        span {
          font-size: 14px;
        }
      }
    }
  }
}

