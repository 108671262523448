.c-modal {
    width: 100%;
    height: 100%;
    position: fixed;

    z-index: 9999;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}


.c-modal-loader {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #1018233d;
    z-index: 10002;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}


.c-modal-body {
    background-color: white;
    max-width: 70%;
    border-radius: 5px;
    /* padding: 3rem 9.5rem; */
    color: black;
    z-index: 9999;


    animation: zoomin .2s ease-in;



    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 480px;
    height: 220px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}


.c-modal-content {
    padding: .5rem .5rem;
    margin-bottom: 1rem;
    font-size: 14px;
    font-weight: 500;
    color: rgb(73, 73, 73);
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.c-modal-buttons {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: .8rem;

}

.c-modal-cancel-btn {

    background-color: white;
    padding: .5rem .5rem;

    border: 0px;
    font-size: 13px;
    letter-spacing: .03rem;
    border-radius: 4px;
    color: rgb(129, 129, 129);

    min-width: 100px;

    cursor: pointer;

    background-color: rgba(0, 130, 0, 0.2) !important;
    color: #008200 !important;



}

.c-modal-ok-btn {

    padding: .5rem .5rem;
    border: 0px;
    font-size: 13px;
    background-color: #101823;
    border-radius: 4px;
    color: white;
    letter-spacing: .03rem;
    min-width: 80px;
    cursor: pointer;





}

.c-modal-end-button {

    width: 140px !important;
    height: 40px !important;
    border-radius: 5px;
    text-align: center;
    background-color: #FFF2F2 !important;
    color: #D75A4A !important;
    border: 1px solid #D75A4A !important;
    margin-top: .8rem;
    font-weight: bold;


}

.c-modal-close-button {


    width: 140px !important;
    height: 40px !important;
    border-radius: 5px;
    text-align: center;
    background-color: transparent !important;
    color: #556D86 !important;
    border: 1px solid #556D86 !important;
    margin-top: .8rem;
    margin-right: 15px;
    font-weight: bold;


}

.c-modal-end-icon {

    height: 60px;
    width: 60px;
    border-radius: 100px;
    background-color: #EDF2F8;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-bottom: 15px;
}




@media only screen and (max-width: 600px) {

    .container {}

}


@keyframes zoomin {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes zoomout {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}