.common-delete-modal-container .delete-model-content {
  width: 360px;
  height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.common-delete-modal-container .delete-model-content div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.8rem 0px;
  text-align: center;
}
.common-delete-modal-container .delete-model-content .icon-container img {
  width: 40px;
}
.common-delete-modal-container .delete-model-content .caption-container {
  color: #092845;
  font-weight: 500;
  font-size: 16.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.common-delete-modal-container .delete-model-content .button-container button {
  width: 30%;
  margin: 0px 0.9rem;
  padding: 0.45rem 0.9rem;
  cursor: pointer;
  font-weight: 600;
}
.common-delete-modal-container .no-btn {
  background-color: white;
  border: 1.8px solid #E1EAF8;
  border-radius: 5px;
  color: #59709B;
}
.common-delete-modal-container .yes-btn {
  background-color: rgba(10, 138, 224, 0.1725490196);
  color: #0A8CE0;
  font-size: 14px;
  border: 1.8px solid rgba(10, 138, 224, 0.0549019608);
  border-radius: 5px;
}/*# sourceMappingURL=common_delete_modal.css.map */