.meet-container {
    width: 100%;
    min-height: 100vh;
    max-width: 100vw;
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* background-image: url("https://www.techrepublic.com/a/hub/i/r/2020/04/07/1813351a-0675-4189-a80d-e0546f7adc23/resize/1200x900/432effa8e0f1ea3011e8a9433cd2c92d/silicon-valley-hbo-background-1920.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    overflow-y: hidden;

}

.end-btn {
    border-radius: 100px;
    height: 50px;
    width: 120px;
    outline: 0;
    border: 0;
    background-color: #ff0000;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-left: 10px;
    cursor: pointer;
    margin-top: 50px;
}

/* .OT_publisher, .OT_subscriber{
   
    height: 100vh !important;
    min-width: 100%;
} */

.vedio-container {

    position: relative;
    min-height: 100vh !important;

    background-color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 100vw;

}

.left-menu-container {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;


}

.left-menu {


    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;


}

.left-menu span {

    margin-top: .5rem;
}

.left-menu ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    min-width: 30vw;



}


.left-menu li {

    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 9.5px;
    color: #ffffff;
    font-weight: bold;
    font-family: 'DM Sans';
    letter-spacing: 0.5px;
    cursor: pointer;
    min-width: 40px;
    margin-right: .3rem;



}

.left-menu-active {
    background-color: transparent !important;
}

.left-menu-active-before {
    border-bottom-right-radius: 20px !important;
}

.left-menu-active-after {
    border-top-right-radius: 20px !important;
}


.left-options-container {}


.break-point {
    border-bottom: 1px solid #e1eaf8;
    padding: 0;
    width: 100%;
    padding-bottom: .5rem !important;
}


.top-menu-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1000;
    cursor: pointer;
    min-height: 50px;
}

.top-menu {

    background-color: rgba(0, 37, 75, 0.72);
    color: white;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    max-width: 100%;
    overflow: auto;
    min-width: 55%;
    transition-duration: .3s;
    max-width: 1008px;
    min-width: 750px;

}




.top-menu-hidden {


    overflow: hidden;
    height: 0%;
    display: none;
    transition-duration: .3s;

}



.top-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.top-menu li {
    padding: 2px 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'DM Sans';
    letter-spacing: 0.5px;
    margin-right: .8rem;
    word-wrap: none;
    flex-wrap: nowrap;

    min-width: 25%;
    max-width: 25%;
    height: 50px;




}

.top-menu-headding {
    font-size: 14px;
    color: #96A8F2;
    font-weight: 500;

}

.top-menu-caption {
    font-size: 16px;
    color: white;
    font-weight: 500;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;

}



.top-menu ul li:not(:first-of-type) {
    border-left: 1.9px solid #464D76;

}

.dragable-component {

    background-color: rgb(0, 0, 0);
    min-height: 100vh !important;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;


}

.user-card {

    position: absolute;
    bottom: 0.5rem;
    left: .5rem;
    background-color: white;
    width: 150px;
    border-radius: 8px;
    margin-right: 0rem;
    height: 150px;
    background-size: cover;
    z-index: 101;
    background-image: url("https://images.unsplash.com/photo-1550791871-0bcd47c97881?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8cGF0aWVudHxlbnwwfHwwfHw%3D&w=1000&q=80");

    z-index: 1000;

    width: 250px !important;
    border-radius: 8px;
    height: 250px !important;

}

.left-sub-menu {

    background-color: #f2f4f6;
    padding-top: 1rem !important;
    height: 100%;
    padding: 0px;
    transition-duration: .3s;
    width: 25vw;

    padding: 0px 3%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;


    border-radius: 8px;

    min-width: 361px;



}

.left-sub-menu-hidden {


    width: 0vw;
    overflow: hidden;
    padding: 0px;
    transition-duration: .3s;

}



.left-sub-menu li {

    padding: .8rem .6rem;
    margin-bottom: .5rem;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #000000 !important;
    font-weight: 500;
    cursor: pointer;


    font-size: 15px;

    background-color: white;
    width: 93%;



}


.test-sub-menu {

    background-color: #f2f4f6;
    padding-top: 1rem !important;
    height: 100%;
    padding: 0px;
    transition-duration: .3s;
    width: 24vw;

    padding: 0px 4%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;




}

.test-sub-menu-hidden {


    display: none;
    width: 0vw;
    overflow: hidden;

}



.test-sub-menu li {

    padding: .6rem .8rem;
    margin-bottom: .6rem;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #000000 !important;
    font-weight: 500;
    cursor: pointer;


    font-size: 15px;

    background-color: white;
    width: 100%;



}

.small-tittle {

    font-size: 15px;
    font-weight: 500;
}

.small-caption {

    font-size: 12px;
    font-weight: 300;
    color: #59709b;
}


.streamer {
    position: relative;
    min-height: 100h;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh !important;
    width: 100%;
    height: 100%;

}




.streamer div {

    width: 100%;
    height: 100%;
}

/* .user-card .OT_publisher,
.OT_subscriber {

    border-radius: 8px;
    min-height: 100% !important;
    min-width: 100% !important;

    width: 250px !important;
    border-radius: 8px;
    height: 250px !important;
} */

.medicines-list {
    background-color: rgb(255, 255, 255);
    min-width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: .5rem .5rem;
    padding: 1rem 0px;
}

.medicines-list .title {
    font-weight: bold;
    font-size: 15px;
    margin-top: .8rem;
}

.medicines-list .caption {
    font-weight: normal;
    font-size: 12px;
    color: #59709b;
    margin-top: .2rem;
    letter-spacing: -0.2px;
    text-align: center;
}


.medicines-list-add-btn {

    flex: 1;
    background-color: #0a8ce0;
    border-radius: 4px;
    border: 0;
    outline: 0;
    color: white;
    font-size: 13.2px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: .8rem 0px;
    margin-top: 1rem;
    width: 100%;


}

.complaints-box {
    width: 95%;
    margin-top: .8rem;
    border: .5px solid rgb(219, 219, 219);
    border-radius: .2rem;
    font-size: .75rem;
    padding: .5rem;
    margin: .8rem 0px;

}

.button-group-container button {

    outline: 0;
    border: 0;
    font-size: 14px;
    padding: .5rem 1.5rem;

}

.active-btn {

    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #586788;
    color: white;

}

.active-btn2 {

    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #586788;
    color: white;
}

.inactive-btn {

    background-color: #ECF4F9;

}

.super-sub-menu-container {

    width: 100%;
    padding: .5rem 1rem;
    min-width: 20vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    font-size: 14px;

}

.back-button-supersubmenu {
    font-size: 16px;
    font-weight: 500;
}

.white-container {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    padding: .8rem .5rem;
    font-size: 14px;
    margin-top: .5rem;
    margin-bottom: .2rem;
    color: #092845;
}

.white-container-heading {
    width: 100%;
    border-bottom: 1px solid #EAE9FB;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 1rem;
    padding-bottom: 10px;
}

.white-container-button {

    width: 100%;
    padding: .5rem .5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #0a8ce0;
    border-radius: 4px;
    color: white;
    font-weight: 500;
    outline: 0;
    border: 0;


}

.lifestyle-item {

    width: 100%;
    border-bottom: 1px solid #EAE9FB;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: .5rem;
    padding: .3rem 0px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.lifestyle-item span {

    font-weight: 500;

}










.vedio-container-left {

    height: 100%;
    background-color: rgb(0, 0, 0);
    display: flex;
    flex: 1;
    position: relative;
    flex: 1;
    background-color: rgb(0, 0, 0);
}

.vedio-container-right {


    background-color: rgb(255, 255, 255);
    display: flex;
    height: 100%;


}


.pre-existing-table {
    width: 100%;

}

.pre-existing-table td {

    padding-top: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid rgba(182, 182, 182, 0.432);


}

.pre-existing-table th {

    padding-top: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1.5px solid rgba(182, 182, 182, 0.432);


}

.add-investigation-small-caption {
    font-size: 10px;
    color: #59709b;
    font-weight: 500;
    letter-spacing: 0.5px;
    width: 90%;
}

.test-textare-input {

    outline: 0px;
    border-radius: 8px;
    width: 90%;
    padding: .5rem .8rem;
    font-size: 14px;
    letter-spacing: -0.18px;
    font-weight: 500;

    border-radius: 4px;
    border: solid 1.5px #e1eaf8;

}


.test-add-btn {

    min-width: 40%;
    background-color: #0a8ce0;
    border-radius: 4px;
    border: 0;
    outline: 0;
    color: white;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: .8rem 0px;
    margin-top: 1rem;


}

.waiting-toast {

    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    z-index: 10000;



}

.waiting-toast .waiting-toast-body {

    background-color: rgb(37, 37, 37);
    width: 44%;
    padding: 1rem .5rem;
    border-radius: 5px;
    z-index: 110;
    color: white;
    font-size: 16px;


    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    backdrop-filter: blur(1px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 168px;
}


.user-profile {

    position: absolute;
    top: 0;
    left: 0;
    color: white;
    background-color: #303135;
    z-index: 125;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


    background-color: #303135;
}

.user-profile-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;
}

.user-avatar {
    height: 65px;
    width: 65px;
    border-radius: 100px;
    object-fit: cover;
}

.user-profile-footer {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: .8rem 1.5rem;
    position: absolute;
    bottom: 0;
    font-weight: bold;
}

.user-profile-footer span {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}





.user-profile2 {

    position: absolute;
    top: 0;
    left: 0;
    color: white;
    background-color: #303135;


    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


    background-color: #303135;
}

.user-profile2-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user2-avatar {
    height: 65px;
    width: 65px;
    border-radius: 100px;
    object-fit: cover;
}

.user-profile2-footer {

    align-items: center;

    bottom: 0;
    font-weight: bold;
    text-align: center;
    width: 100% !important;
    margin-top: 10px;
}

.normaltile {
    border: 1px solid #4AD395;
  }
.abnormaltile {
    border: 1px solid #F1BC2C;
}
.criticaltile {
    border:1px solid #CB2027;
  }
  






@media screen and (max-width: 1320px) {
    .top-menu {

        min-width: 750px;
        max-width: 1008px;

    }
}

@media screen and (max-width: 1083px) {
    .top-menu {

        min-width: 750px;
        max-width: 1008px;

    }
}

@media screen and (max-width: 900px) {
    .top-menu {

        min-width: 750px;
        max-width: 1008px;

    }
}