.common-delete-modal-container{

    
.delete-model-content{

    width: 360px;
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  
    div{
      width: 100%;
  
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: .8rem 0px;
      text-align: center;
    }
  
    .icon-container{
      img{
        width: 40px;
      }
    }
  
    .caption-container{
      color: #092845;
      font-weight: 500;
      font-size: 16.5px;
  
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .button-container{
  
      button{
        width: 30%;
        margin: 0px .9rem;
        padding: .45rem .9rem;
        cursor: pointer;
        font-weight: 600;
        
      }
  
    }
    
  }
  
  .no-btn{
  
    background-color: white;
    border: 1.8px solid #E1EAF8;
    border-radius: 5px;
    color:#59709B
   
  }
  
  .yes-btn{
    background-color: #0a8ae02c;
    color:#0A8CE0;
    font-size: 14px;
   
  
    border: 1.8px solid #0a8ae00e;
    border-radius: 5px;
  }
  

}
