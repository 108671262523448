

.OT_mode-auto{
    display: none!important;
}

.iframe-container{
    height: 100%;
    width: 0px;
    overflow: hidden;
    transition-duration: .5s;
}

.active{
    width: 65vw;
}

.iframe-content{
    height: 100%;
    width: 100%;
}



.vedio-container-mobile{

    height: 100%;
    width: 35vw;
    background-color: rgb(0, 0, 0);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: .5rem;
}


.vedio-container-mobile .vedio-mobile{

    background-color: #202126;
    width: 95%;
    margin: .8rem 0px;
    height: 45%;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;


}




.user-profile-small{

  
    color: white;
    background-color: #303135;
    z-index: 125;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


   background-color: #303135;
}

.user-profile-container-small{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
}

.user-avatar-small{
    height: 65px;
    width: 65px;
    border-radius: 100px;
    object-fit: cover;
}

.user-profile-footer-small{

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: .8rem 1.5rem;
    position: absolute;
    bottom: 0;
    font-weight: bold;
}

.user-profile-footer span{

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}


.invite-container{

    padding:1rem;

}

.invite-container .title{
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .5px;
}

.invite-container .body{

    margin-top: 1rem;

}

.invite-container input{

    margin-top: .6rem;

}
.invite-container .medicines-list-add-btn {

    width: 100%;
    margin-top:1.5rem;
}

.appointment-details-reports-list{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.appointment-details-reports-list .report-card{

    width: 100%;
    padding: .5rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.appointment-details-reports-list .report-card .report-header{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.appointment-details-reports-list .report-card .report-header .name{

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}


