.allergies-card-container{
    background-color: white;
    width: 100%;
    border-radius: 8px;
    padding: 0.8rem 0.8rem;
    margin-top: .8rem;
    font-size: 14px;
    letter-spacing: -0.18px;
    font-weight: 500;
}

.allergies-card-container .ant-form-item-explain-error {

    font-size: 13px !important;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 5px;

}


.allergies-card-input{
    outline: 0px;
    border-radius: 8px;
    width: 100%;
    padding: .5rem .8rem;
    font-size: 14px;
    letter-spacing: -0.18px;
    font-weight: 500;

    border-radius: 4px;
    border: solid 1.5px #e1eaf8;
}

.ant-form-item-has-error .allergies-card-input {
    border-color: red !important;
}


.allergies-card-input-50{

    outline: 0px;
    border-radius: 8px;
    width:48%;
    padding: .5rem .8rem;
    font-size: 14px;
    letter-spacing: -0.18px;
    font-weight: 500;

    border-radius: 4px;
    border: solid 1.5px #e1eaf8;

}

.ant-form-item-has-error .allergies-card-input-50 {
    border-color: red !important;
}


.allergies-card-container  .ant-select div {


}

.remove-text{
    font-size: 14px;
    font-weight: 500;
    color: #fb1e00;
    margin-left:.8rem;
}