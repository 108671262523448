.medication-card-container {
    background-color: white;
    width: 100%;
    border-radius: 8px;
    padding: 0.8rem 0.8rem;
    margin-top: .8rem;
    font-size: 14px;
    letter-spacing: -0.18px;
    font-weight: 500;

}
.medication-card-container .invite-body {
  margin-top: 10px;
  margin-bottom: 10px;

}
.medication-card-container .invite-body .message {
    color:#9dc951 !important;
    padding-top: 10px;

  
  }
.medication-card-container .ant-radio-group {

  margin-bottom: 10px;

}

.medication-card-container .ant-form-item-explain-error {

    font-size: 13px !important;
    font-weight: 400;
    margin-top: 5px;

}

.medication-card-input {
    outline: 0px;
    border-radius: 8px;
    width: 100%;
    padding: .5rem .8rem;
    font-size: 14px;
    letter-spacing: -0.18px;
    font-weight: 500;

    border-radius: 4px;
    border: solid 1.5px #e1eaf8;
}

.ant-form-item-has-error .medication-card-input {
    border-color: red !important;
}



.medication-card-input-50 {

    outline: 0px;
    border-radius: 8px;
    width: 48%;
    padding: .5rem .8rem;
    font-size: 14px;
    letter-spacing: -0.18px;
    font-weight: 500;

    border-radius: 4px;
    border: solid 1.5px #e1eaf8;

}

.ant-form-item-has-error .medication-card-input-50 {
    border-color: red !important;
}


.medication-card-container .ant-select div {}

.remove-text {
    font-size: 14px;
    font-weight: 500;
    color: #fb1e00;
    margin-left: .8rem;
}

.medication-cardduration-status-wrap {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
}

::placeholder {
    color: #bfbfbf;
  }