.commonLoader{
    position: absolute;
    width: 50%;
}

.prescription_sync_status_indicator{

    height: 30px;
        width: 30px;

    div{
        height: 30px;
        width: 30px;
        border-radius: 100px;
    }

    .completed{
        background-color: green;
    }
    .pending{
        background-color: red;
    }

}


.rotate {
    animation: rotation 1.8s infinite linear;
  }


@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .link-style{
    color: #208ce1;
    &:hover{
      text-decoration: underline;
    }
  }


  .empty-table-data{

    width: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(128, 128, 128);
    font-size: 15px;
    letter-spacing: .5px;
    margin-top: 1rem;

    .empty-table-body{

      border-radius: 100px;
      background-color: #EFF0F5;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      height: 170px;
      width: 170px;
      

    }

    .empty-table-footer{

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title{
        font-size: 20px;
        font-weight: 300;
        margin-top: 1rem;
        color: #222222;
      }

      .caption{
        font-size: 13px;
        font-weight: 300;

      }

    }

  }


  .top-margin-50{
    margin-top: 170px !important;
    margin-bottom: 170px !important;
  }



  .center{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }