.Faq_Main {
    .Faq_Top {
      padding-top: 59px;
      padding-bottom: 59px;
      background-image: url("../../assets/terms_bg.svg");
     
      color: #ffffff;
      .Top_Head {
        font-size: 50px;
        color: #ffffff;
        opacity: 1;
        font-weight: 600;
        margin-bottom: 0px;
      }
    }
    .Faq_Tabs_main {
      border-bottom: 2px solid #e7edf2;
      background: #FCFDFE;
      .Faq_Tabs {
        display: flex;
  
        opacity: 1;
        padding-top: 21px;
        padding-bottom: 21px;
  
        .Tab {
          color: #8da2b5;
          opacity: 1;
          cursor: pointer;
          margin: 0 45px 0 0;
          font-size: 18px;
          font-weight: 500;
          position: relative;
        }
        .activeClass {
          color: #208ce1;
          cursor: pointer;
          &:after {
            content: "";
            position: absolute;
            height: 2px;
            width: 100%;
            background: #000;
            bottom: -22px;
            left: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width:767px) {
    .Faq_Main {
      .Faq_Top {
       
        padding: 40px 0;
        .Top_Head {
        font-size: 30px;
        }
      }
      .Tab {
        margin: 0 25px 0 0 !important;
      }
    }
  }

  
  .FaqContent_main {
    margin-top: 30px;
    padding-bottom: 80px;

    padding-left: 35px;
    padding-right: 35px;
    p {
      font-size: 18px;
      color: #162D43;
      text-align: justify;
    }
    h6 {
      font-weight: 500;
      font-size: 18px;
    }
    a {
      text-decoration: none;
      padding-left: 5px;
    }
    .headings {
      letter-spacing: 0px;
      color: #162d43;
      opacity: 1;
      font-size: 20px;
      margin-top: 28px;
      font-weight: 600;
    }
    .subContent_main {
      margin-top: 20px;
      margin-bottom: 20px;
      .subContent {
        padding-bottom: 5px;
        display: flex;
        align-items: baseline;
        span {
          margin-left: 10px;
          font-size: 18px;
          color: #162D43;
        }
      }
    }
    .btn_wrapper {
      margin-top: 41px;
      margin-bottom: 41px;
      display: flex;
      .btn_1 {
        margin-right: 10px;
        background-color: #ebf1f8;
        border-color: #ebf1f8 !important;
        color: #7b8a98;
        border-radius: 5px;
        opacity: 1;
        font-size: 16px;
        font-weight: 500;
        padding: 8px 20px;
        font-family: "IBM Plex Sans";
      }
      .active_btn1 {
        background-color: #1485d8;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
     
    }
  }
  @media screen and (max-width:767px) {
    .FaqContent_main{
      padding-bottom: 30px;
      .btn_wrapper {
        margin: 25px 0;
        .btn_1 {
          font-size: 13px;
        }
      }
      .headings {
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        text-align: justify;
      }
      .subContent_main {
        .subContent {
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
  
  