.container {

    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(255, 255, 255);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}