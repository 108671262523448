$small: 300px;
$banner_image_medium: 1390px;
$tab_size: 1146px;


.patient-details-table {

    width: 100%;
    margin-left: 1rem;


    tr {
        td {
            font-size: 14px;

            vertical-align: text-top;
            padding-bottom: 15px;

            .patient-details-item-container {
                background-color: #ECF5FC;
                padding: 12px;
                border-radius: 5px;
                padding: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                width:558px;


                input {
                    outline: none;
                    border: 0px;
                    padding: 5px 10px;
                    border: 1px solid #E1EAF8;
                    font-weight: 500;
                    color: #212429;
                }


                textArea {
                    outline: none;
                    border: 0px;
                    padding: 5px 10px;
                    border: 1px solid #E1EAF8;
                    font-weight: 500;
                    color: #212429;
                }
            }

            .vitals-reading-container {

                width: 100%;
                min-height: 150px;
                background-color: white;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 5px;
                border: 2px solid #CB2027;

                .rpm-dashboard-tile_body_item_question {
                    font-size: 15px;
                }

                .rpm-dashboard-tile_body_item_answer {
                    font-size: 15px;
                }

            }

            .patient-details-notes {

                width: 100%;
                min-height: 150px;
                background-color: white;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 5px;
                font-weight: 500;
                color: #212429;


            }

            .sbt-btn {
                border-radius: 5px;
                background-color: #0a8ce0;
                color: white;
                font-weight: 600;
                font-size: 15px;
                outline: 0px;
                border: 0px;
                cursor: pointer;
               
                height: 49px;
                padding: 0px 4rem;
                margin-top: 15px;
            }



            &:nth-child(1) {
                // your css code   
                font-weight: 500;
                width: 150px;
                white-space: nowrap;
            }

            &:nth-child(2) {
                // your css code   
                font-weight: 500;
                width: 20px !important;
            }

            &:nth-child(3) {
                // your css code   
                width: 30vw;
            }

        }

        td{
            &:nth-child(1){
               white-space: nowrap;
            }
        }
    }

    .patient-details-table-red{
        color: #CB2027;
    }
    
    .patient-details-table-breaker{
        border-bottom: 1px solid red;
    }

    .instruction-to-nurse-submit-container{

        width: 558px;
        text-align: end;
    }

}

.c2md-table-container{

    width: 100% !important;

}

.c2md-table{
    width: 100%;
    margin-top: 1rem;
    border-collapse:collapse;
    font-size: 13px;

    tr:nth-child(even) {
        background-color: #F5F7FB;

        .more-btn{
            background-color:#ffffff ;
         }
    }

    .more-btn{
        padding: .5rem .9rem;
        background-color:#F4F6F8 ;
        border: 0px;
        outline: 0px;
        border-radius: 5px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
     }

    th{
        text-align: start;
        font-size: 16px;
        font-weight: bold;
        background-color: #F5F7FB;
        height: 50px;
        padding: .2rem 1rem;
        border-spacing: 0;
      
        flex-wrap: nowrap;

        white-space: nowrap;
        overflow: hidden;
      
    }

    td{
        text-align: start;
        font-size: 14px;
        height: 50px;
        padding: .5rem 1rem;
        border-spacing: 0;
        cursor: pointer;
        font-weight: 400;

        white-space: nowrap;
      
    }

   
}


.send-ambulance-container{

    .ant-form-item{
        margin-bottom: 0px !important;
        width: 100%;
        .ant-form-item-explain-error{
            font-size: 13px;
            margin-top: 3px;
        }
    
    }

}
