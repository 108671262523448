.homepage_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  color: #208CE1;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0.8px;
}/*# sourceMappingURL=homepage.css.map */