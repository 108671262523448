.patient-details-table {
  width: 100%;
  margin-left: 1rem;
}
.patient-details-table tr td {
  font-size: 14px;
  vertical-align: text-top;
  padding-bottom: 15px;
}
.patient-details-table tr td .patient-details-item-container {
  background-color: #ECF5FC;
  padding: 12px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 558px;
}
.patient-details-table tr td .patient-details-item-container input {
  outline: none;
  border: 0px;
  padding: 5px 10px;
  border: 1px solid #E1EAF8;
  font-weight: 500;
  color: #212429;
}
.patient-details-table tr td .patient-details-item-container textArea {
  outline: none;
  border: 0px;
  padding: 5px 10px;
  border: 1px solid #E1EAF8;
  font-weight: 500;
  color: #212429;
}
.patient-details-table tr td .vitals-reading-container {
  width: 100%;
  min-height: 150px;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  border: 2px solid #CB2027;
}
.patient-details-table tr td .vitals-reading-container .rpm-dashboard-tile_body_item_question {
  font-size: 15px;
}
.patient-details-table tr td .vitals-reading-container .rpm-dashboard-tile_body_item_answer {
  font-size: 15px;
}
.patient-details-table tr td .patient-details-notes {
  width: 100%;
  min-height: 150px;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  font-weight: 500;
  color: #212429;
}
.patient-details-table tr td .sbt-btn {
  border-radius: 5px;
  background-color: #0a8ce0;
  color: white;
  font-weight: 600;
  font-size: 15px;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  height: 49px;
  padding: 0px 4rem;
  margin-top: 15px;
}
.patient-details-table tr td:nth-child(1) {
  font-weight: 500;
  width: 150px;
  white-space: nowrap;
}
.patient-details-table tr td:nth-child(2) {
  font-weight: 500;
  width: 20px !important;
}
.patient-details-table tr td:nth-child(3) {
  width: 30vw;
}
.patient-details-table tr td:nth-child(1) {
  white-space: nowrap;
}
.patient-details-table .patient-details-table-red {
  color: #CB2027;
}
.patient-details-table .patient-details-table-breaker {
  border-bottom: 1px solid red;
}
.patient-details-table .instruction-to-nurse-submit-container {
  width: 558px;
  text-align: end;
}

.c2md-table-container {
  width: 100% !important;
}

.c2md-table {
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
  font-size: 13px;
}
.c2md-table tr:nth-child(even) {
  background-color: #F5F7FB;
}
.c2md-table tr:nth-child(even) .more-btn {
  background-color: #ffffff;
}
.c2md-table .more-btn {
  padding: 0.5rem 0.9rem;
  background-color: #F4F6F8;
  border: 0px;
  outline: 0px;
  border-radius: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.c2md-table th {
  text-align: start;
  font-size: 16px;
  font-weight: bold;
  background-color: #F5F7FB;
  height: 50px;
  padding: 0.2rem 1rem;
  border-spacing: 0;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
}
.c2md-table td {
  text-align: start;
  font-size: 14px;
  height: 50px;
  padding: 0.5rem 1rem;
  border-spacing: 0;
  cursor: pointer;
  font-weight: 400;
  white-space: nowrap;
}

.send-ambulance-container .ant-form-item {
  margin-bottom: 0px !important;
  width: 100%;
}
.send-ambulance-container .ant-form-item .ant-form-item-explain-error {
  font-size: 13px;
  margin-top: 3px;
}/*# sourceMappingURL=common.css.map */