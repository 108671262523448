.popup_faq_container {
   

    h3{
        font-weight: 500;
        font-size: 15px;
      }
   
    p {
      font-size: 13px;
      color: #162D43;
      text-align: justify;
    }
    h6 {
      font-weight: 500;
      font-size: 12px;
    }
    a {
      text-decoration: none;
      padding-left: 5px;
    }
    .headings {
      letter-spacing: 0px;
      color: #162d43;
      opacity: 1;
      font-size: 20px;
      margin-top: 28px;
      font-weight: 600;
    }
    .subContent_main {
      margin-top: 20px;
      margin-bottom: 20px;
      .subContent {
        padding-bottom: 5px;
        display: flex;
        align-items: baseline;
        span {
          margin-left: 10px;
          font-size: 18px;
          color: #162D43;
        }
      }
    }
    .btn_wrapper {
      margin-top: 41px;
      margin-bottom: 41px;
      display: flex;
      .btn_1 {
        margin-right: 10px;
        background-color: #ebf1f8;
        border-color: #ebf1f8 !important;
        color: #7b8a98;
        border-radius: 5px;
        opacity: 1;
        font-size: 16px;
        font-weight: 500;
        padding: 8px 20px;
        font-family: "IBM Plex Sans";
      }
      .active_btn1 {
        background-color: #1485d8;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
     
    }
  }