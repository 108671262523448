.meet-footer {
  position: relative;
  width: 100%;
  background-color: #202123;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 7%;
  z-index: 100000 !important;
  padding: 10px;
}
.meet-footer .footer-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 33.33%;
}
.meet-footer .left {
  justify-content: flex-start;
}
.meet-footer .center {
  justify-content: center;
}
.meet-footer .right {
  justify-content: flex-end;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.buttons-container button {
  border-radius: 100px;
  height: 30px;
  width: 30px;
  outline: 0;
  border: 0;
  margin: 0px 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition-duration: 0.5s;
}
.buttons-container button :hover {
  transform: scale(1.05);
}
.buttons-container .active {
  background-color: red;
}
.buttons-container .end_call_btn {
  width: 60px;
}

.meet_container_v2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: red;
  margin: 0px;
}
.meet_container_v2 .close-btn-containr {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding: 0px 15px;
  cursor: pointer;
}
.meet_container_v2 .close-btn-containr .page-name {
  font-weight: 500;
  cursor: pointer;
  font-size: 15px;
  text-transform: capitalize;
}
.meet_container_v2 .meet_body_v2 {
  width: 100%;
  height: 93%;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}/*# sourceMappingURL=meetfooter.css.map */